import { Injectable } from '@angular/core';
import { AuthService, ClientService, LanguageService } from '@haleo-frontend/data-access/services';
import { AppService, TrackingUtils } from '@haleo-frontend/utils';
import { ModalController, Platform } from '@ionic/angular';
import { lastValueFrom, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { Client } from '@haleo-frontend/data-access/models';
import { Router } from '@angular/router';
import { CalendlyInlineComponent } from '@haleo-frontend/ui';

declare const Calendly: any;
declare const window: any;

@Injectable({
    providedIn: 'root'
})
export class CalendlyService {
    private show: boolean;
    private openHubspot: boolean;
    private isAuthenticated: boolean;

    constructor(private platform: Platform,
                private languageService: LanguageService,
                private clientService: ClientService,
                private authService: AuthService,
                private appService: AppService,
                private router: Router,
                private modalController: ModalController) {
    }

    async showWidget(source: string, isDisability = false, mainURL = '') {
        this.show = true;
        this.openHubspot = false;
        this.isAuthenticated = false;
        let urlBase: string;

        urlBase = 'https://calendly.com';

        if (isDisability) {
            urlBase += '/invalidite-haleo-disability';
        } else {
            urlBase += '/haleo-agent';
        }

        if (this.languageService.localeShort === 'fr') {
            urlBase += '/fr?hide_gdpr_banner=1';
        } else {
            urlBase += '/en?hide_gdpr_banner=1';
        }

        if (await lastValueFrom(this.authService.isAuthenticated())) {
            this.isAuthenticated = true;
            this.authService.isCaseManager()
                .pipe(flatMap(isCaseManager => {

                    if (isCaseManager) {
                        return of(urlBase);
                    } else {
                        return this.clientService.get()
                            .pipe(map(client => {

                                if (client.account.key == 'ulaval') {
                                    urlBase = 'https://calendly.com/haleo-agent/ulaval/fr?hide_gdpr_banner=1'
                                }

                                if (client.account.key == 'pharmacotherapypilot2') {
                                    if (this.languageService.localeShort === 'fr') {
                                        urlBase = 'https://calendly.com/haleo-agent/pilote-pharmacotherapie-phase-2?month=2023-04';
                                    } else {
                                        urlBase = 'https://calendly.com/haleo-agent/tappering-pilot-phase-2?month=2023-04';
                                    }
                                }

                                if (client.account.key == 'fiera') {
                                    if (this.languageService.localeShort === 'fr') {
                                        urlBase = 'https://calendly.com/haleo-agent/fieracapitalfr?hide_gdpr_banner=1';
                                    } else {
                                        urlBase = 'https://calendly.com/haleo-agent/fieracapital?hide_gdpr_banner=1';
                                    }
                                }

                                if (client.account.key == 'demo') {
                                    this.show = false;
                                    this.openHubspot = true;
                                }

                                if (client.salesforceContactId) {
                                    urlBase += '&sfid=' + client.salesforceContactId;
                                }

                                return this.prefilledData(client, urlBase);
                            }));
                    }
                }))
                .subscribe((url) => {
                    this.open(mainURL !== '' ? mainURL : url, source);
                });
        } else {
            this.open(mainURL !== '' ? mainURL : urlBase, source);
        }
    }

    open(url: string, source: string) {

        if (this.openHubspot) {
            window.open('https://info.haleoclinic.com/meetings/julien-heon');
        }

        if (this.show) {
            Calendly.initPopupWidget({
                url,
                utm: {
                    utmCampaign: this.platform.is('capacitor') ? 'native' : 'web',
                    utmSource: source
                }
            });

            window.addEventListener('message', (event: any) => {
                if (event.data.event) {

                    switch (event.data.event) {
                        case 'calendly.date_and_time_selected':

                            if (source === 'screener-app') {
                                TrackingUtils.trackEvent('questionnaire_calendly_day_and_time');
                            } else {
                                TrackingUtils.trackEvent(source + '_calendly_slot_selected');
                            }

                            break;
                        case 'calendly.event_scheduled':

                            if (this.isAuthenticated && this.appService.getAppKey() === 'screener-app') {
                                this.clientService.sendWorkValidationEmail().pipe().subscribe();
                            }

                            if (source === 'screener-app') {
                                TrackingUtils.trackEvent('questionnaire_calendly_meeting_booked');
                            } else {
                                TrackingUtils.trackEvent(source + '_calendly_booked');
                            }

                            // Manually hide Calendly spinner because it's visible on mobile
                            const spinner: any = document.getElementsByClassName('calendly-spinner');

                            if (spinner.length > 0) {
                                spinner[0].style.display = 'none';
                                this.show = false;
                            }

                            break;
                    }
                }
            });

            const popupClose = document.getElementsByClassName('calendly-popup-close')[0];
            popupClose.addEventListener('click', () => {
                this.show = false;
            });

            const closeOverlay = document.getElementsByClassName('calendly-close-overlay')[0];
            closeOverlay.addEventListener('click', () => {
                this.show = false;
            });
        }
    }

    async openCalendly(source: string, isDisability = false, mainURL: any = null) {
        const url = await this.getCalendlyUrl(isDisability, mainURL);

        const modal = await this.modalController.create({
            component: CalendlyInlineComponent,
            componentProps: {
                url: url,
                source: source,
            },
            showBackdrop: true,
            backdropDismiss: true,
            cssClass: 'popover-calendly'
        });
        await modal.present();
    }

    initInlineWidget(divId: string, url: string, source: string) {

        Calendly.initInlineWidget({
            url: url,
            parentElement: document.getElementById(divId),
            utm: {
                utmCampaign: 'web',
                utmSource: source
            }
        });

        window.addEventListener('message', (event: any) => {
            if (event.data.event) {

                switch (event.data.event) {
                    case 'calendly.date_and_time_selected':

                        if (source === 'screener-app') {
                            TrackingUtils.trackEvent('questionnaire_calendly_day_and_time');
                        } else {
                            TrackingUtils.trackEvent(source + '_calendly_slot_selected');
                        }

                        break;
                    case 'calendly.event_scheduled':

                        if (this.isAuthenticated && this.appService.getAppKey() === 'screener-app') {
                            this.clientService.sendWorkValidationEmail().pipe().subscribe();
                        }

                        if (source === 'screener-app') {
                            TrackingUtils.trackEvent('questionnaire_calendly_meeting_booked');
                        } else {
                            TrackingUtils.trackEvent(source + '_calendly_booked');
                        }

                        // Manually hide Calendly spinner because it's visible on mobile
                        const spinner: any = document.getElementsByClassName('calendly-spinner');

                        if (spinner.length > 0) {
                            spinner[0].style.display = 'none';
                            this.show = false;
                        }

                        break;
                }
            }
        });
    }

    public prefilledData(client: Client, baseUrl: string) {

        let url = new URL(baseUrl);

        const { firstName, lastName, email, phoneNumber } = client;

        if (firstName) {
            url.searchParams.set('first_name', firstName.charAt(0).toUpperCase() + firstName.slice(1));
        }

        if (lastName) {
            url.searchParams.set('last_name', lastName.charAt(0).toUpperCase() + lastName.slice(1));
        }

        if (phoneNumber) {
            // add 1 for the country code
            url.searchParams.set('a1', '1' + phoneNumber);
        }

        if (email) {
            url.searchParams.set('email', email);
        }

        return url.toString();
    }

    async getCalendlyUrl(isDisability = false, mainURL: any = null) {

        this.isAuthenticated = false;
        let urlBase: string;

        urlBase = 'https://calendly.com';

        if (isDisability) {
            urlBase += '/invalidite-haleo-disability';
        } else {
            urlBase += '/haleo-agent';
        }

        if (this.languageService.localeShort === 'fr') {
            urlBase += '/fr?hide_gdpr_banner=1';
        } else {
            urlBase += '/en?hide_gdpr_banner=1';
        }

        const isLoggedIn = await lastValueFrom(this.authService.isAuthenticated());

        if (!isLoggedIn) {
            return mainURL ?? urlBase;
        }

        this.isAuthenticated = true;

        const isCaseManager = await lastValueFrom(this.authService.isCaseManager());

        if (isCaseManager) {
            return urlBase;
        }

        const client = await lastValueFrom(this.clientService.getClientWithoutCache());

        if (client.account.key == 'ulaval') {
            urlBase = 'https://calendly.com/haleo-agent/ulaval/fr?hide_gdpr_banner=1';
        }

        if (client.account.key == 'pharmacotherapypilot2') {
            if (this.languageService.localeShort === 'fr') {
                urlBase = 'https://calendly.com/haleo-agent/pilote-pharmacotherapie-phase-2?month=2023-04';
            } else {
                urlBase = 'https://calendly.com/haleo-agent/tappering-pilot-phase-2?month=2023-04';
            }
        }

        if (client.account.key == 'fiera') {
            if (this.languageService.localeShort === 'fr') {
                urlBase = 'https://calendly.com/haleo-agent/fieracapitalfr?hide_gdpr_banner=1';
            } else {
                urlBase = 'https://calendly.com/haleo-agent/fieracapital?hide_gdpr_banner=1';
            }
        }

        if (client.salesforceContactId) {
            urlBase += '&sfid=' + client.salesforceContactId;
        }

        return this.prefilledData(client, mainURL ?? urlBase);
    }
}
