<div class="p-5 w-full h-screen bg-haleo-warm-light pb-20">
    <div class="float-right w-10 h-10 rounded-full flex ion-align-items-center bg-white cursor-pointer" (click)="close()">
        <ion-icon
            class="w-full text-4xl"
            slot="icon-only"
            name="close"
        ></ion-icon>
    </div>
    <div id="calendly-inline-embeded" data-auto-load="false" style="min-width:320px;height: 100%;">
</div>
