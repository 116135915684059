import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { NgxMaskModule } from 'ngx-mask';
import { NgxEchartsModule } from 'ngx-echarts';
import { UtilsModule } from '@haleo-frontend/utils';

import { PopoverModal } from './popover/popover.modal';
import { NewPopoverModal } from './new-popover/new-popover.modal';
import { FullpagePopoverModal } from './fullpage-popover/fullpage-popover.modal';
import { CountryListComponent } from './country-list/country-list.component';
import { CountryPipe } from './country-list/country.pipe';
import { ProvinceListComponent } from './province-list/province-list.component';
import { ProvincePipe } from './province-list/province.pipe';
import { MessageListComponent } from './message-list/message-list.component';
import { WeekGanttComponent } from './week-gantt/week-gantt.component';
import { CheckboxComponent } from './thought-record/checkbox/checkbox.component';
import { QuantitativeListComponent } from './thought-record/quantitative-list/quantitative-list.component';
import { TextComponent } from './thought-record/text/text.component';
import { TextListComponent } from './thought-record/text-list/text-list.component';
import { GanttChartComponent } from './gantt/gantt-chart.component';
import { GuidedComponent } from './thought-record/text-list/guided.component';
import { AddElementModal } from './thought-record/quantitative-list/add-element.modal';
import { AddElementCheckboxModal } from './thought-record/checkbox/add-element-checkbox.modal';
import { PickerTimeOverlayComponent } from './assessment/picker-time-overlay/picker-time-overlay.component';
import { PickerSelectOverlayComponent } from './assessment/picker-select-overlay/picker-select-overlay.component';
import { PickerColumnComponent } from './assessment/picker-column/picker-column.component';
import { ButtonComponent } from './assessment/button/button-component';
import { ErrorComponent } from './assessment/error/error.component';
import { WarningComponent } from './warning/warning.component';
import { ExpandableHeaderDirective } from './directives/expendable-header.directive';
import { ModuleComponent } from './module/module.component';
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';
import { ClientEditComponent } from './client-edit/client-edit.component';
import {
    PickerDateTimeOverlayComponent
} from './assessment/picker-date-time-overlay/picker-date-time-overlay.component';

import { RadioComponent } from './questions/radio/radio.component';
import { CheckboxComponent as CheckboxQuestionComponent } from './questions/checkbox/checkbox.component';
import { DateComponent } from './questions/date/date.component';
import { ExplanationComponent } from './questions/explanation/explanation.component';
import { NumberComponent } from './questions/number/number.component';
import { NumberRangeComponent } from './questions/number-range/number-range.component';
import { PhoneNumberComponent } from './questions/phone-number/phone-number.component';
import { TextComponent as TextQuestionComponent } from './questions/text/text.component';
import { TimeComponent } from './questions/time/time.component';
import { TimeRangeComponent } from './questions/time-range/time-range.component';
import { SleepDiaryTimeSelectComponent } from './diary-time-select/diary-time-select.component';
import { SleepDiaryPopoverComponent } from './sleep-diay-popover/sleep-diary-popover.component';
import { CalendlyInlineComponent } from './calendly-inline/calendly-inline.component';

@NgModule({
    declarations: [
        PopoverModal,
        NewPopoverModal,
        FullpagePopoverModal,
        AddElementModal,
        AddElementCheckboxModal,
        CountryListComponent,
        ProvinceListComponent,
        MessageListComponent,
        WeekGanttComponent,
        GanttChartComponent,
        CheckboxComponent,
        QuantitativeListComponent,
        TextComponent,
        TextListComponent,
        GuidedComponent,
        CountryPipe,
        ProvincePipe,
        PickerTimeOverlayComponent,
        PickerSelectOverlayComponent,
        PickerColumnComponent,
        ButtonComponent,
        ErrorComponent,
        WarningComponent,
        ModuleComponent,
        ResetPasswordFormComponent,
        ClientEditComponent,
        PickerDateTimeOverlayComponent,
        //
        ExpandableHeaderDirective,
        //
        RadioComponent,
        CheckboxQuestionComponent,
        DateComponent,
        ExplanationComponent,
        NumberComponent,
        NumberRangeComponent,
        PhoneNumberComponent,
        TextQuestionComponent,
        TimeComponent,
        TimeRangeComponent,
        SleepDiaryTimeSelectComponent,
        SleepDiaryPopoverComponent,
        CalendlyInlineComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        MomentModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxEchartsModule,
        UtilsModule,
        NgxMaskModule
    ],
    exports: [
        CountryListComponent,
        NewPopoverModal,
        ProvinceListComponent,
        MessageListComponent,
        WeekGanttComponent,
        GanttChartComponent,
        CheckboxComponent,
        QuantitativeListComponent,
        TextComponent,
        TextListComponent,
        GuidedComponent,
        ProvincePipe,
        CountryPipe,
        PickerTimeOverlayComponent,
        PickerSelectOverlayComponent,
        PickerColumnComponent,
        ButtonComponent,
        ErrorComponent,
        WarningComponent,
        ModuleComponent,
        ResetPasswordFormComponent,
        ClientEditComponent,
        PickerDateTimeOverlayComponent,
        //
        ExpandableHeaderDirective,
        UtilsModule,
        //
        RadioComponent,
        CheckboxQuestionComponent,
        DateComponent,
        ExplanationComponent,
        NumberComponent,
        NumberRangeComponent,
        PhoneNumberComponent,
        TextQuestionComponent,
        TimeComponent,
        TimeRangeComponent,
        SleepDiaryTimeSelectComponent,
        SleepDiaryPopoverComponent,
        CalendlyInlineComponent
    ]
})
export class UiModule {
}
