import { Component, Input, OnInit } from '@angular/core';
import { CalendlyService } from '@haleo-frontend/utils';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-client-calendly-inline',
    templateUrl: './calendly-inline.component.html',
    styleUrls: ['./calendly-inline.component.scss'],
})
export class CalendlyInlineComponent implements OnInit {

    @Input() url: string;
    @Input() source: string;

    constructor(private calendlyService: CalendlyService,
                private modalController: ModalController) {
    }

    async ngOnInit() {
        this.calendlyService.initInlineWidget('calendly-inline-embeded', this.url, this.source);
    }

    close() {
        this.modalController.dismiss();
    }
}
